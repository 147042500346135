@import './mixins.scss';

:root {
    --d-spacing-half: calc(var(--d-spacing-single) / 2);
    --d-spacing-single: 8px;
    --d-spacing-double: calc(var(--d-spacing-single) * 2);
    --d-spacing-triple: calc(var(--d-spacing-single) * 3);

    --d-font-weight-normal: 500;

    --d-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

    // classes
    .d-horizontal-content-paddings {
        padding-left: var(--d-spacing-double);
        padding-right: var(--d-spacing-double);
    }

    .d-vertical-content-paddings {
        padding-top: var(--d-spacing-double);
        padding-bottom: var(--d-spacing-double);
    }

    .d-vertical-content-padding-bottom {
        padding-bottom: var(--d-spacing-double);
    }

    .d-full-content-paddings {
        @extend .d-horizontal-content-paddings;
        @extend .d-vertical-content-paddings;
    }

    .d-light-background {
        background-color: var(--ion-color-light);
    }

    .d-card {
        border-radius: 8px;
        margin: 0 0 0 0;
    }

    .d-text-page-padding {
        --padding-start: var(--d-spacing-double);
        --padding-end: var(--d-spacing-double);
        --padding-top: 20%;
        --padding-bottom: var(--d-spacing-double);
        /* top | left and right | bottom */
        padding: 20% var(--d-spacing-double) var(--d-spacing-double);
    }

    .d-text-content-container {
        margin-top: 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-weight: normal;
    }

    .d-content-separator {
        width: 100%;
        background-color: var(--ion-color-medium);
        margin: var(--d-spacing-single) 0 var(--d-spacing-single) 0;
    }

    .d-button-primary-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .d-footer-button-container {
        padding: var(--d-spacing-single) var(--d-spacing-double) var(--d-spacing-double) var(--d-spacing-double);
        box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.25);
    }

    .d-button-square {
        text-transform: none;
        font-weight: bold;
        --border-radius: 4px;
        font-size: medium;
        margin: var(--d-spacing-half);
        flex-grow: 1;
        flex-basis: 40%;
        text-align: center;
    }

    .d-button-square-primary {
        @extend .d-button-square;
        --background: var(--ion-color-secondary);
        --border-style: solid;
        --border-width: 1px;
        --border-color: var(--ion-color-secondary);
        color: white;
    }

    .d-button-square-primary-inverse {
        @extend .d-button-square;
        --background: white;
        --border-style: solid;
        --border-width: 1px;
        --border-color: var(--ion-color-secondary);
        color: var(--ion-color-secondary);
    }

    .d-button-round {
        --padding-start: 1.3em;
        --padding-end: 1.3em;
        --padding-top: 0.8em;
        --padding-bottom: 0.8em;
        --border-color: var(--ion-color-secondary);
        --border-radius: 20px;
        --border-width: 2px;
        --border-style: solid;
        --box-shadow: var(--d-box-shadow);
        --background: white;
        color: var(--ion-color-secondary);
        text-transform: none;
        text-align: end;
        font-weight: bold;
        max-width: 100%;
        height: fit-content;
        white-space: pre-line;
    }

    .d-button-round-light {
        @extend .d-button-round;
        --border-width: 1px;
        font-weight: normal;
    }

    .d-button-round[disabled] {
        --box-shadow: 0 0 0 0;
    }

    // dwin buddy classes

    .d-buddy-text-content {
        background-color: #f8faff;
        border: 2px dashed var(--ion-color-primary);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;

        padding: var(--d-spacing-double);

        --padding-start: var(--d-spacing-double);
        --padding-end: var(--d-spacing-double);
        --padding-bottom: var(--d-spacing-double);
        --padding-top: var(--d-spacing-double);
    }

    .d-buddy-text-content-header {
        font-size: x-large;
        font-weight: bold;
        margin-bottom: var(--d-spacing-single);
        div {
            display: flex;
            span {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }

    .d-buddy-text-header-img {
        height: 80px;
        width: auto;
        margin-right: var(--d-spacing-single);
    }

    ion-button {
        // In ionic v7 this changed to 36px, this reverts this change
        min-height: 28px;
    }

    ion-button[shape='circle'] {
        --border-radius: 50%;
        --background: white;
        --border-color: var(--ion-color-primary);
        --border-width: 1px;
        --box-shadow: var(--d-box-shadow);
        --padding-start: 0;
        --padding-end: 0;
        height: 28px;
        width: 28px;
    }

    .d-back-button-circle {
        --border-radius: 100%;
        --background: white;
        --border-color: var(--ion-color-primary);
        --border-width: 0.1em;
        --color: white;
        --padding-start: 0.3em;
        --padding-end: 0.3em;
        --padding-top: 0.3em;
        --padding-bottom: 0.3em;
        --box-shadow: var(--d-box-shadow);
        height: 28px;
        margin: 0 0 0 0;

        img {
            height: 100%;
            width: 100%;
        }
    }

    .d-segment-header-title {
        --padding-bottom: calc(var(--d-spacing-single) / 2);
        padding-bottom: calc(var(--d-spacing-single) / 2);
        @include h1-style;
    }

    .d-chip-primary {
        color: white;
        background-color: var(--ion-color-primary) !important;
        font-weight: bold;
        margin: 0;
    }

    .close-x-icon {
        height: 28px;
        width: 28px;
    }

    .dwin-selection-button {
        width: 100%;
        text-transform: none;
        color: black;
        --border-radius: 8px;
        --border-width: 0;
        --border-color: var(--ion-color-medium);
        --background: white;
        --box-shadow: var(--d-box-shadow);
        padding-top: var(--d-spacing-double);
        height: fit-content;
        padding-left: 0;
        padding-right: 0;
        margin: 0;
    }

    .dwin-selection-button-active {
        @extend .dwin-selection-button;
        font-weight: bold;
        color: white;
        --border-color: var(--ion-color-primary);
        --background: var(--ion-color-primary);
        --box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25);

        div {
            color: white;
        }
    }

    .progress-row {
        .progress-text-col {
            font-size: small;
            font-weight: normal;
            line-height: 20px;
            align-self: center;
            justify-content: center;
        }

        .progress-bar-col {
            align-self: center;
            justify-content: center;
            padding-left: var(--d-spacing-single);
        }

        ion-progress-bar {
            --progress-background: var(--ion-color-primary);
            --background: var(--ion-color-light);
            height: calc(var(--d-spacing-half) * 2.5);
            border-radius: var(--d-spacing-half);
        }
    }

    .feed-card-text {
        @include p1-style;
        line-height: 120%;
        flex-grow: 1;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }

    .feed-card-icon {
        display: flex;
    }

    .state-value-subtitle {
        @include p2-style;
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;

        div {
            align-self: center;
        }
    }

    .dot-marker {
        border-radius: 50%;
        height: 10px;
        width: 10px;
        background-color: var(--ion-color-secondary);
    }

    .expired-tag {
        @include p2-style;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: calc(var(--d-spacing-half) + var(--d-spacing-half) / 2);
        border-radius: 8px;
        color: var(--ion-color-secondary);
        background-color: rgba(140, 3, 67, 0.08);
        width: fit-content;
        height: fit-content;
        margin-left: var(--d-spacing-single);
    }

    .no-word-break {
        hyphens: none;
    }
}
