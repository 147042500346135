@mixin h1-style {
    font-size: 19px;
    font-weight: bold;
    color: var(--ion-color-primary);
    line-height: 130%;
}

@mixin p1-style {
    font-size: 16px;
    font-weight: 500;
    color: var(--ion-color-primary);
    line-height: 150%;
}

@mixin p1-berry-style {
    font-size: 16px;
    font-weight: 500;
    color: var(--ion-color-secondary);
    line-height: 150%;
}

@mixin p1-white-style {
    font-size: 16px;
    font-weight: 500;
    color: white;
    line-height: 150%;
}

@mixin p2-style {
    font-size: 13px;
    font-weight: 500;
    color: var(--ion-color-primary);
    line-height: 150%;
}

@mixin p2-berry-style {
    font-size: 13px;
    font-weight: 500;
    color: var(--ion-color-secondary);
    line-height: 150%;
}
