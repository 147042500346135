/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import './dwin.scss';

// swiper
@import '~swiper/swiper-bundle.min.css';
@import '~swiper/swiper.min.css';

@import './mixins.scss';

:root {
    --ion-color-primary: #303f9f;
    --ion-color-primary-rgb: 48, 63, 159;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #2a378c;
    --ion-color-primary-tint: #4552a9;
    --ion-color-secondary: #8c0343;
    --ion-color-secondary-rgb: 140, 3, 67;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #7b033b;
    --ion-color-secondary-tint: #981c56;
    --ion-color-tertiary: #e2e4f4;
    --ion-color-tertiary-rgb: 226, 228, 244;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #c7c9d7;
    --ion-color-tertiary-tint: #e5e7f5;
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;
    --ion-color-danger: #d91a73;
    --ion-color-danger-rgb: 217, 26, 115;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #bf1765;
    --ion-color-danger-tint: #dd3181;
    --ion-color-light: #eff1fa;
    --ion-color-light-rgb: 239, 241, 250;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d2d4dc;
    --ion-color-light-tint: #f1f2fb;
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;
}

body {
    background-color: var(--ion-background-color) !important;
}

.h1 {
    @include h1-style;
}

.p1 {
    @include p1-style;
}

.p1-berry {
    @include p1-berry-style;
}

.p1-white {
    @include p1-white-style;
}

.p2 {
    @include p2-style;
}

.p2-berry {
    @include p2-berry-style;
}

swiper-slide {
    * {
        text-align: start;
    }
}

* {
    line-height: 24px;
    hyphens: auto;
}

// common
.page-padding {
    --padding-start: var(--d-spacing-double);
    --padding-end: var(--d-spacing-double);
    --padding-top: var(--d-spacing-double);
    --padding-bottom: var(--d-spacing-double);
    padding: var(--d-spacing-double);
}

hr {
    clear: both;
    display: block;
    background-color: #c4c4c4;
    height: 1px;
}
div,
p,
ion-row,
ion-row p,
ion-col p,
ion-col {
    color: var(--ion-color-primary);
    font-weight: var(--d-font-weight-normal);
}

span,
div,
p,
ion-row,
ion-row p,
ion-col p,
ion-col {
    color: var(--ion-color-primary);
    font-weight: var(--d-font-weight-normal);
}

.hidden {
    visibility: hidden;
}

.hide-scrollbar {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

ion-toast {
    --background: var(--ion-color-secondary);
    --box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    --color: white;
    font-weight: bolder;
    text-align: center;
}

ion-button {
    letter-spacing: normal;
}

ion-modal.mini-modal {
    --height: auto;
    --width: 100%;
    &::part(backdrop) {
        --backdrop-opacity: var(--ion-backdrop-opacity, 0.9);
        background: var(--ion-color-tertiary);
    }
}

ion-popover::part(backdrop) {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.5);
    background: var(--ion-color-tertiary);
}
ion-modal.mini-modal-bottom {
    --height: auto;
    --width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    &::part(backdrop) {
        --backdrop-opacity: var(--ion-backdrop-opacity, 0.9);
        background: var(--ion-color-tertiary);
    }
}

ion-app,
ion-modal {
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
}

ion-loading.loading-service {
    background: rgba(226, 228, 244, 0.5);
    --backdrop-opacity: 0;

    .loading-wrapper {
        background: transparent;
        box-shadow: none !important;
        z-index: -1;
    }

    ion-spinner {
        height: 12vh;
        width: 12vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.highest-prio-modal {
    z-index: 999999 !important;
}
